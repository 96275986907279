.map_container {
  padding: 10px;
  overflow: hidden; /* Ensure content within the container does not protrude beyond rounded corners */
  width: 95%;
}

.contact_header {
  font-size: 42px;
  margin: 0;
}

.text_container {
  margin-top: 10px;
  padding: 10px;
}

.about_ilustration_container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
}

.info-container {
  display: grid;
  padding: 10px;
  grid-template-columns: 30% 20% 25% 25%;
  list-style: none;
  text-align: center;
}

.icon-color {
  color: rgb(128, 128, 128);
  margin-top: 7px;
  justify-self: flex-end;
}

.info-item {
  padding: 10px;
  display: inline-block;
}

.info-text {
  color: black;
  font-size: 14px;
  letter-spacing: 0.1em;
  justify-self: flex-end;
  padding-left: 10px;
}

.text-wrapper {
  text-decoration: none !important;
}

.contact_img {
  padding: 10px;
  height: 28vh;
  align-self: flex-end;
}

@media screen and (max-width: 1000px) {
  .text_container {
    display: block;
    font-size: 12px;
    padding: 10px 10px 0 10px;
  }
  .info-container {
    grid-template-columns: none;
    padding: 0;
  }
  .map_container {
    padding: 0 5px 0 8px;
  }
  .info-text {
    font-size: 10px;
  }
  .info-item {
    padding: 5px;
  }
  .text_container img {
    max-block-size: 110px;
  }
  .contact_img_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact_header {
    text-align: center;
    font-size: 32px;
  }
  .about_ilustration_container {
    grid-template-columns: none;
  }
}
