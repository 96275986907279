.footer {
  background: #e7e5e5;
  text-align: center; /* Center align text within the footer */
  padding: 10px 0; /* Add padding to the top and bottom for spacing */
  position: fixed; 
  bottom: 0; /* Position the footer at the bottom of the viewport */
  left: 0; /* Position the footer at the left edge of the viewport */
  right: 0; /* Position the footer at the right edge of the viewport */
  width: 100%; /* Make the footer span the full width of the viewport */
  z-index: 999; /* Optional: Set a higher z-index to ensure it's on top of other content */
  height: 70px;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Horizontally center .footer_container */
}

.footer_container {
  padding: 0;
  margin: 0;
  width: 984px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: center;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
  padding: 10px 20px 10px 20px;
}

.copyrights {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.excavator_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.excavator_icon {
  width: 50px;
  height: 50px;
  max-width: 100%;
  animation: moveExcavator 8s ease-in-out infinite;
}

/* Define keyframes for animation stages */
@keyframes moveExcavator {
  0% {
    transform: translateX(-200%);
  }
  33.33% {
    transform: translateX(0);
  }
  66.66% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@media screen and (max-width: 660px) {
  .social-icons {
    gap: 12px;
    padding: 5px 10px 5px 10px;
  }
}

@media screen and (min-width: 400px) {
  .footer-mobile {
    max-width: none; /* Remove the max-width for smaller screens */
    width: auto; /* Take the full width of the viewport */
    margin: 0; /* Remove any margin to fill the entire width */
  }
}