.services_header_container {
  margin-left: 15px;
}

.services_header {
  font-size: 42px;
}

.card_picture {
  width: 120px;
  height: 120px;
}

.card {
  display: flex;
  padding-left: 40px;
  align-items: center;
  padding-bottom: 10px;
}

.card > p {
  padding: 15px;
}

.services_card_container {
  padding-top: 20px;
}

@media screen and (max-width: 1000px) {
  .services_header {
    text-align: center;
    font-size: 32px !important;
  }
  .card {
    display: block;
    padding-left: 5px;
  }
  .card p {
    padding: 0;
    margin: 0;
  }
  .card_picture {
    text-align: center;
    display: inherit;
  }
  .services_picture_container img {
    max-block-size: 60px;
  }

  .services_picture_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .services_card_container {
    padding-top: 0;
    font-size: smaller;
  }
  .card_picture {
    width: inherit;
    height: inherit;
  }
}

@media screen and (max-height: 750px) {
  .services_card_container {
    padding-top: 0;
    font-size: 9px;
  }
}
