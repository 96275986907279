.informations_picture {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -40px;
}

.informations_picture img {
  width: 100%; /* Ensure the image fits the container width */
  height: 100%; /* Ensure the image fits the container height */
}

.informations_header {
  font-size: 42px;
}

.informations_ilustration {
  max-block-size: 500px;
}

.informations_text_container {
  padding-top: 5px;
}

.informations_container {
  display: flex;
  align-items: center;
}

/* Add this style to center the items when the width is less than or equal to 1000px */
@media screen and (max-width: 1000px) {
  .informations_container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
  }

  .informations_text_container {
    text-align: center; /* Center text within the container */
    padding-top: 0;
    font-size: smaller;
  }

  .informations_ilustration {
    max-block-size: 300px;
  }
  .informations_header {
    text-align: center;
    font-size: 32px !important;
  }
}

/* Add this style to display items as a grid when the width is greater than 1000px */
@media screen and (min-width: 1001px) {
  .informations_container {
    display: grid;
    grid-template-columns: 55% 45%;
    gap: 20px; /* Adjust the gap between grid items */
  }
}

@media screen and (max-height: 750px) {
  .informations_ilustration {
    max-block-size: 150px;
  }
}
