.header {
  height: 160px;
  background: #fffdfd;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Horizontally center .footer_container */
  width: 100%; /* Make the header span the full width of the viewport */
}

.header_container {
  padding: 0;
  margin: 0;
  width: 984px;
}

.logo {
  float: left;
  padding: 0;
  margin-top: 30px;
}

.logo img {
  max-block-size: 110px;
}

.pages {
  padding: 0;
  margin: 0;
  width: 400px;
  float: right;
  height: 85px;
}

.pages ul {
  padding: 120px 0 0 0;
  margin: 0;
  list-style: none;
  border: 0;
  height: 40px;
}

.pages ul a {
  float: left;
  margin: 0 2px 0 0;
  padding: 14px 0 14px 0;
  color: #434343;
  font:
    normal 11px Arial,
    Helvetica,
    sans-serif;
  text-decoration: none;
}

.pages ul li a span {
  padding: 14px 12px 14px 12px;
}

.pages ul li a.active span {
  background: #efefef;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .header {
    height: 100px;
    width: auto;
  }
  .header_container {
    display: block;
    gap: 30px;
    width: auto;
  }
  .pages{
    height: 20px;
  }
  .pages ul a {
    margin: 0 2px 0 0;
    padding: 10px 0 10px 0;
    color: #434343;
    font:
      normal 9px Arial,
      Helvetica,
      sans-serif;
    text-decoration: none;
  }
  .logo {
    padding-top: 8px !important;
    text-align: center;
    float: inherit;
    padding: 0;
    margin: 0;
  }
  .logo img {
    max-block-size: 32px;
  }
  .pages ul {
    padding: 30px 0 0 0;
    display: flex;
    justify-content: center;
  }
}

