body {
  margin: 0;
  padding: 0;
  width: 100%;
  background: #efefef !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400; /* Regular */
}

.pages_container {
  justify-content: center;
  align-items: center;
  width: 984px;
  margin: 0 auto;
  background: #efefef;
}

@media screen and (max-width: 1000px) {
  .pages_container {
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  .pages_container {
    width: auto;
  }
}
