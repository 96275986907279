.home_container {
  padding: 40px;
  width: 100%;
  text-align: center;
}

.home_header_container {
  margin-left: 15px;
}

.home_header {
  font-size: 42px;
}

@media screen and (max-width: 1000px) {
  .home_header {
    text-align: center;
    font-size: 32px !important;
  }
  .home_container {
    padding: 0;
  }
}
