.aboutus_header {
  font-size: 42px;
}

@media screen and (max-width: 1000px) {
  .aboutus_header {
    text-align: center;
    font-size: 32px !important;
  }
  .about_text > p {
    padding: 0 20px 0 20px;
  }
}

@media screen and (max-width: 800px) {
  .about_text > p {
    font-size: 11px;
  }
}

@media screen and (max-height: 700px) {
  .about_text > p {
    font-size: 10px;
  }
}
