/* DragPictureEffect.css */


  /* Ensure the text is visible on all pictures */
  .slider .picture {
    position: relative;
  }
  
  .drag-picture-container {
    overflow: hidden;
    width: 100%;
    max-height: 700px; /* Adjust the height as needed */
  }
  
  .slider {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .picture {
    flex: 0 0 100%;
    width: 100%;
    height: 90%;
    overflow: hidden;
  }
  
  .picture img {
    object-fit: cover;
    max-width: 100%;
    max-height: 50vh;
    border-radius: 25px;
    border: 2px black;
  }
  
  .spiner-container {
    background: #efefef;
    display: flex;
    justify-content: center;
    min-height: 80vh;
  }
  